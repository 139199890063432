<template>
  <div v-if="isVisible">
    <v-card
      class="mb-5"
      max-width="1100"
      outlined
    >
      <v-card-title
        :class="['clickable', isModified ? 'blue' : 'blue-grey']"
        class="lighten-5 section__title"
        @click.prevent="collapseCard"
      >
        <v-icon class="mx-3" v-if="section?.icon">{{ section?.icon }}</v-icon>
        {{ section?.title }}
        <v-spacer></v-spacer>
        <v-tooltip v-if="section.commentShow && !IS_CLIENT" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="comment-icon mx-3"
              :class="{'not-empty': commentExist}"
              v-text="'mdi-comment-outline'"
              @click.stop="commentModalToggle"
              v-bind="attrs"
              v-on="on"
              :disabled="FIELDS_DISABLED"
            />
          </template>
          <span>Adviser comments</span>
        </v-tooltip>

      </v-card-title>
      <div :class="{ 'section__hide': isHideSection }" :key="isExistedDataChanged">
        <slot name="section" />
      </div>

      <v-fade-transition>
        <v-card-actions :class="[ 'section__controls', { 'section__hide': isHideSection } ]">
          <slot name="print" />

          <v-btn
            v-if="canShowSaveButton"
            block
            color="warning"
            @click.prevent="save"
            :loading="isSaving"
          >
            SAVE
          </v-btn>
        </v-card-actions>
      </v-fade-transition>
    </v-card>
    <v-dialog
        v-if="section.commentShow"
        v-model="commentDialog"
        @click:outside="commentCancel"
        width="500"
      >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Adviser comment
        </v-card-title>

        <v-divider></v-divider>

        <v-simple-table>
          <template v-slot:default>
            <v-card-text class="mt-4">
              <v-textarea
                outlined
                name="input-7-4"
                label="Adviser comment"
                placeholder="Adviser comment"
                v-model="commentText"
                rows="8"
                auto-grow
              ></v-textarea>
            </v-card-text>
          </template>
        </v-simple-table>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="commentCancel"
            color="primary"
            text
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="FIELDS_DISABLED"
            @click="commentSave"
            color="primary"
            text
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import not from '@/utils/common';

import { hasAccess } from '../../services/section';

export default {
  name: 'BaseSection',

  props: {
    clientCanEdit: {
      type: Boolean,
      default: false,
    },

    isVisible: {
      type: Boolean,
      default: true,
    },

    isModified: {
      type: Boolean,
      default: false,
    },

    isExistedDataChanged: {
      type: Boolean,
      default: false,
    },

    section: {
      type: Object,
      required: true,
      validator(section) {
        const hasTitleProperty = Boolean(Object.prototype.hasOwnProperty.call(section, 'title'));

        return hasTitleProperty;
      },
    },
  },

  emits: ['onSectionSaveClick'],

  data() {
    return {
      isSaving: false,
      isHideSection: false,
      isSectionDisabled: true,

      commentDialog: false,
      commentText: '',
    };
  },

  computed: {
    ...mapGetters(['CURRENT_EDIT_ROLE', 'EDITED_APPLICATION', 'FIELDS_DISABLED', 'IS_CLIENT']),

    commentExist() {
      return this.EDITED_APPLICATION?.data?.comments?.[this.section?.key];
    },

    canShowSaveButton() {
      const isSectionNotDisabled = not(this.isSectionDisabled);

      const canShowSaveButton = Boolean(this.isModified
      && (isSectionNotDisabled || this.clientCanEdit));

      return canShowSaveButton;
    },
  },

  async created() {
    this.commentText = this.EDITED_APPLICATION?.data?.comments?.[this.section?.key];
    this.isSectionDisabled = await this.checkIsSectionDisabled();
    this.$store.commit('setFieldsDisabled', this.isSectionDisabled);
  },

  methods: {
    commentModalToggle() {
      this.commentDialog = !this.commentDialog;
    },

    commentCancel() {
      this.commentText = this.EDITED_APPLICATION?.data?.comments?.[this.section?.key];
      this.commentModalToggle();
    },

    async commentSave() {
      const editAppCopy = this.EDITED_APPLICATION?.data?.comments;

      editAppCopy[this.section?.key] = this.commentText;

      const commentData = {
        id: '',
        sectionKey: 'comments',
        payload: editAppCopy,
        subApplicant: false,
      };

      await this.$store.dispatch('storeSectionData', commentData);

      this.commentModalToggle();
    },

    save() {
      this.$emit('onSectionSaveClick');
    },

    collapseCard() {
      this.isHideSection = !this.isHideSection;
    },

    async checkIsSectionDisabled() {
      const userHaveAccess = await hasAccess(this.section.access);

      if (userHaveAccess) {
        const userCanEdit = await hasAccess([this.CURRENT_EDIT_ROLE]);

        return not(userCanEdit);
      }

      return true;
    },
  },

  watch: {
    EDITED_APPLICATION() {
      this.commentText = this.EDITED_APPLICATION?.data?.comments?.[this.section?.key];
    },
  },
};
</script>

<style lang="scss">
  .comment-icon:not(:last-child) {
    margin-right: 20px;
  }

  .clickable {
    pointer-events: initial;
  }

  .section {
    &__title {
      cursor: pointer;
    }

    &__controls {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 5px 27px 25px 27px;

      .v-btn--block {
        min-width: initial !important;
      }
    }

    &__hide {
      display: none;
    }
  }

  .not-empty.mdi-comment-outline:before {
    color: #1976d2;
  }
</style>
