var render = function () {
  var _vm$section, _vm$section2, _vm$section3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isVisible ? _c('div', [_c('v-card', {
    staticClass: "mb-5",
    attrs: {
      "max-width": "1100",
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "lighten-5 section__title",
    class: ['clickable', _vm.isModified ? 'blue' : 'blue-grey'],
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.collapseCard.apply(null, arguments);
      }
    }
  }, [(_vm$section = _vm.section) !== null && _vm$section !== void 0 && _vm$section.icon ? _c('v-icon', {
    staticClass: "mx-3"
  }, [_vm._v(_vm._s((_vm$section2 = _vm.section) === null || _vm$section2 === void 0 ? void 0 : _vm$section2.icon))]) : _vm._e(), _vm._v(" " + _vm._s((_vm$section3 = _vm.section) === null || _vm$section3 === void 0 ? void 0 : _vm$section3.title) + " "), _c('v-spacer'), _vm.section.commentShow && !_vm.IS_CLIENT ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          staticClass: "comment-icon mx-3",
          class: {
            'not-empty': _vm.commentExist
          },
          attrs: {
            "disabled": _vm.FIELDS_DISABLED
          },
          domProps: {
            "textContent": _vm._s('mdi-comment-outline')
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.commentModalToggle.apply(null, arguments);
            }
          }
        }, 'v-icon', attrs, false), on))];
      }
    }], null, false, 358113676)
  }, [_c('span', [_vm._v("Adviser comments")])]) : _vm._e()], 1), _c('div', {
    key: _vm.isExistedDataChanged,
    class: {
      'section__hide': _vm.isHideSection
    }
  }, [_vm._t("section")], 2), _c('v-fade-transition', [_c('v-card-actions', {
    class: ['section__controls', {
      'section__hide': _vm.isHideSection
    }]
  }, [_vm._t("print"), _vm.canShowSaveButton ? _c('v-btn', {
    attrs: {
      "block": "",
      "color": "warning",
      "loading": _vm.isSaving
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.save.apply(null, arguments);
      }
    }
  }, [_vm._v(" SAVE ")]) : _vm._e()], 2)], 1)], 1), _vm.section.commentShow ? _c('v-dialog', {
    attrs: {
      "width": "500"
    },
    on: {
      "click:outside": _vm.commentCancel
    },
    model: {
      value: _vm.commentDialog,
      callback: function callback($$v) {
        _vm.commentDialog = $$v;
      },
      expression: "commentDialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5 grey lighten-2"
  }, [_vm._v(" Adviser comment ")]), _c('v-divider'), _c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('v-card-text', {
          staticClass: "mt-4"
        }, [_c('v-textarea', {
          attrs: {
            "outlined": "",
            "name": "input-7-4",
            "label": "Adviser comment",
            "placeholder": "Adviser comment",
            "rows": "8",
            "auto-grow": ""
          },
          model: {
            value: _vm.commentText,
            callback: function callback($$v) {
              _vm.commentText = $$v;
            },
            expression: "commentText"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 3810643300)
  }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.commentCancel
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    attrs: {
      "disabled": _vm.FIELDS_DISABLED,
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.commentSave
    }
  }, [_vm._v(" Ok ")])], 1)], 1)], 1) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }